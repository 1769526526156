import React from 'react'
import PropTypes from 'prop-types'
import SEO from '../components/Seo'
import Layout from '../layouts/'
import PageBody from '../components/Pages/MarketSimple'

const IndexPage = ({ location }) => {
  return (
    <Layout>
      <SEO
        queryCoinId="ETH"
        type="coin-view"
        keywords="Ethereum, Ethereum Chile, Comprar Ethereum, Como comprar Ethereum"
      />
      <PageBody location={location} staticCoin={'ETH'} />
    </Layout>
  )
}

IndexPage.propTypes = {
  location: PropTypes.object
}

export default IndexPage
