import React from 'react'
import PropTypes from 'prop-types'
import { Div, Text, Row, Col } from 'atomize-jnh'
import { getCoinData } from '../../../data/currencies'
import { useIntl } from 'gatsby-plugin-intl'

const DescriptionCard = ({ coinId, langCode }) => {
  const intl = useIntl()

  const data = getCoinData(intl)

  return coinId === 'default' ? (
    <div />
  ) : (
    <Div m={{ t: '36px' }}>
      <Div>
        <Row justify="center">
          <Col size={{ xs: '12', md: '12' }}>
            <Div
              shadow="3"
              bg="#fff"
              p={{ t: '24px', b: '24px', l: { xs: '24px', md: '36px' }, r: { xs: '24px', md: '36px' } }}
              rounded="md">
              <Text textSize="title">
                {intl.formatMessage({ id: 'marketSimple.about' })}
                {data[coinId].name}
              </Text>
              {data[coinId].description.map((value, index) => (
                <Text m={{ t: '12px' }} key="index" textSize="body">
                  {value}
                </Text>
              ))}
              <Text
                textAlign="right"
                m={{ r: '1rem' }}
                textSize="caption">{`${data[coinId].source} `}</Text>
            </Div>
          </Col>
        </Row>
      </Div>
    </Div>
  )
}

export default DescriptionCard

DescriptionCard.propTypes = {
  coinId: PropTypes.string,
  langCode: PropTypes.string
}
