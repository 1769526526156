import React, { useState, useEffect } from 'react'
import { Div, Text, Row, Col, Button } from 'atomize-jnh'
import CoinCircle from '../../CoinCircle'
import { FaAngleRight } from 'react-icons/fa'
import { useIntl } from 'gatsby-plugin-intl'
import { currenciesCodeArray } from '../../../data/currencies'
import { navigate } from 'gatsby'
import numberWithCommas from '../../../helpers/numberWithCommas'

export default function OtherCryptos () {
  const intl = useIntl()
  const recommendedCryptosIndexes = [0, 1, 4, 3, 8, 7]
  const [criptos, setCriptos] = useState()

  async function fetchData () {
    const data = await fetch('https://stats.orionx.com/ticker')
    const currencies = await data.json()
    setCriptos(currencies)
    return currencies
  }

  useEffect(() => {
    fetchData()
  }, [])

  function renderCryptoPrice (pairCode, secondaryCurrency) {
    if (!criptos) return

    const coinTicker = criptos[`${pairCode}${secondaryCurrency}`]
    return (
      <Text textColor="rgb(176, 178, 180)" textSize="caption" h="15px">
        ${`${numberWithCommas(coinTicker.lastPrice)}`}
      </Text>
    )
  }

  return (
    <Div>
      <Text m={{ t: '3rem' }} textWeight="700" textSize="title">
        {intl.formatMessage({ id: 'marketSimple.otherCurrencies' })}
      </Text>
      <Div m={{ t: '2rem' }}>
        <Row>
          {recommendedCryptosIndexes.map((value, key) => {
            return (
              <Col d="flex" justify="center" key={key} size={{ xs: '6', md: '2' }}>
                <Button
                  onClick={() => navigate(`/market-simple/?id=${currenciesCodeArray[value].code}`)}
                  prefix={
                    <Div pos="absolute" left="12px" top="19px">
                      <CoinCircle size={24} currencyCode={currenciesCodeArray[value].code} />
                    </Div>
                  }
                  suffix={<FaAngleRight style={{ position: 'absolute', right: '12px' }} />}
                  bg="#fff"
                  rounded="md"
                  textColor="#37465a"
                  w={{ xs: '10.1rem', md: '100%' }}
                  h="60px"
                  m={{ t: { xs: '12px', md: '0' } }}
                  p={{ r: '1.5rem', l: '1rem' }}
                  shadow="3"
                  hoverShadow="4">
                  <Div>
                    <Text>{currenciesCodeArray[value].name}</Text>
                    {renderCryptoPrice(currenciesCodeArray[value].code, 'CLP')}
                  </Div>
                </Button>
              </Col>
            )
          })}
        </Row>
      </Div>
    </Div>
  )
}
