import React, { useState, useEffect } from 'react'
import PropTypes from 'prop-types'
import { Container, Row, Col, Div } from 'atomize-jnh'
import Graph from './Graph'
import Operate from './Operate'
import OtherCryptos from './OtherCryptos'
import DirectoryInfo from './DirectoryInfo'
import CoinDescription from './CoinDescription'
import CoinTitle from './CoinTitle'
import { useIntl } from 'gatsby-plugin-intl'

const PageBody = ({ location, staticCoin }) => {
  const query = new URLSearchParams(location.search)
  const queryCoinId = query.get('id')
  const [coinId, setCoinId] = useState('default')
  const intl = useIntl()
  useEffect(() => {
    if (queryCoinId !== null) {
      setCoinId(queryCoinId)
    } else if (staticCoin) {
      setCoinId(staticCoin)
    }
  }, [coinId])

  return (
    <Div style={{ background: '#e9f3fe' }} p={{ t: '1rem', b: '9vh' }}>
      <Container>
        <DirectoryInfo />
        <CoinTitle coinId={coinId} />

        <Row>
          <Col size={{ xs: '12', md: '8' }}>
            <Graph coinId={coinId} pairCode={`${coinId}CLP`} />
          </Col>
          <Col size={{ xs: '12', md: '4' }}>
            <Operate pairCode={`${coinId}CLP`} coinId={coinId} />
          </Col>
        </Row>

        <CoinDescription coinId={coinId} langCode={intl.formatMessage({ id: 'langCode' })} />

        <OtherCryptos />
      </Container>
    </Div>
  )
}
PageBody.propTypes = {
  location: {
    search: PropTypes.object
  },
  staticCoin: PropTypes.string
}

export default PageBody
