const getCoinData = intl => {
  const data = {
    BCH: {
      name: 'Bitcoin Cash',
      description: [intl.formatMessage({ id: 'marketSimple.BCH.description.0' })],
      source: intl.formatMessage({ id: 'marketSimple.BCH.source' })
    },
    ETH: {
      name: 'Ether',
      description: [
        intl.formatMessage({ id: 'marketSimple.ETH.description.0' }),
        intl.formatMessage({ id: 'marketSimple.ETH.description.1' })
      ],
      source: intl.formatMessage({ id: 'marketSimple.ETH.source' })
    },
    BTC: {
      name: 'Bitcoin',
      description: [intl.formatMessage({ id: 'marketSimple.BTC.description.0' })],
      source: intl.formatMessage({ id: 'marketSimple.BTC.source' })
    },
    XRP: {
      name: 'XRP',
      description: [intl.formatMessage({ id: 'marketSimple.XRP.description.0' })],
      source: intl.formatMessage({ id: 'marketSimple.XRP.source' })
    },
    XLM: {
      name: 'Stellar Lumens',
      description: [intl.formatMessage({ id: 'marketSimple.XLM.description.0' })],
      source: intl.formatMessage({ id: 'marketSimple.XLM.source' })
    },
    TRX: {
      name: 'Tron',
      description: [intl.formatMessage({ id: 'marketSimple.TRX.description.0' })],
      source: intl.formatMessage({ id: 'marketSimple.TRX.source' })
    },
    BNB: {
      name: 'Binance Coin',
      description: [intl.formatMessage({ id: 'marketSimple.BNB.description.0' })],
      source: intl.formatMessage({ id: 'marketSimple.BNB.source' })
    },
    DAI: {
      name: 'Dai',
      description: [intl.formatMessage({ id: 'marketSimple.DAI.description.0' })],
      source: intl.formatMessage({ id: 'marketSimple.DAI.source' })
    },
    USDT: {
      name: 'Tether USD',
      description: [intl.formatMessage({ id: 'marketSimple.USDT.description.0' })],
      source: intl.formatMessage({ id: 'marketSimple.USDT.source' })
    },
    LTC: {
      name: 'Litecoin',
      description: [intl.formatMessage({ id: 'marketSimple.LTC.description.0' })],
      source: intl.formatMessage({ id: 'marketSimple.LTC.source' })
    },
    CHA: {
      name: 'Chaucha',
      description: [intl.formatMessage({ id: 'marketSimple.CHA.description.0' })],
      source: intl.formatMessage({ id: 'marketSimple.CHA.source' })
    },
    LUK: {
      name: 'LUKA',
      description: [intl.formatMessage({ id: 'marketSimple.LUK.description.0' })],
      source: intl.formatMessage({ id: 'marketSimple.LUK.source' })
    },
    DASH: {
      name: 'DASH',
      description: [intl.formatMessage({ id: 'marketSimple.DASH.description.0' })],
      source: intl.formatMessage({ id: 'marketSimple.DASH.source' })
    },
    EOS: {
      name: 'EOS',
      description: [intl.formatMessage({ id: 'marketSimple.EOS.description.0' })],
      source: intl.formatMessage({ id: 'marketSimple.EOS.source' })
    },
    DOT: {
      name: 'DOT',
      description: [intl.formatMessage({ id: 'marketSimple.DOT.description.0' })],
      source: intl.formatMessage({ id: 'marketSimple.DOT.source' })
    }
  }
  return data
}
const currenciesCodeArray = [
  { name: 'Bitcoin', code: 'BTC', pair: 'CLP' },
  { name: 'Ether', code: 'ETH', pair: 'CLP' },
  { name: 'XRP', code: 'XRP', pair: 'CLP' },
  { name: 'Stellar', code: 'XLM', pair: 'CLP' },
  { name: 'Tron', code: 'TRX', pair: 'CLP' },
  { name: 'Binance Coin', code: 'BNB', pair: 'CLP' },
  { name: 'Dai', code: 'DAI', pair: 'CLP' },
  { name: 'Tether USD', code: 'USDT', pair: 'CLP' },
  { name: 'Litecoin', code: 'LTC', pair: 'CLP' },
  { name: 'Chaucha', code: 'CHA', pair: 'CLP' },
  { name: 'Luka', code: 'LUK', pair: 'CLP' },
  { name: 'Bitcoin Cash', code: 'BCH', pair: 'CLP' },
  { name: 'Dash', code: 'DASH', pair: 'CLP' },
  { name: 'EOS', code: 'EOS', pair: 'CLP' },
  { name: 'DOT', code: 'DOT', pair: 'CLP' },
]

const currenciesNames = {
  BTC: {
    currencyName: 'Bitcoin',
    geckoName: 'bitcoin',
    unicode: 'Ƀ',
    decimals: 6
  },
  CLP: {
    currencyName: 'Peso chileno',
    unicode: '$',
    decimals: 0
  },
  ETH: {
    currencyName: 'Ether',
    geckoName: 'ethereum'
  },
  XRP: {
    currencyName: 'XRP',
    geckoName: 'XRP'
  },
  XLM: {
    currencyName: 'Stellar Lumens',
    geckoName: 'stellar'
  },
  TRX: {
    currencyName: 'Tron',
    geckoName: 'tron'
  },
  BNB: {
    currencyName: 'Binance Coin',
    geckoName: 'binancecoin'
  },
  DAI: {
    currencyName: 'DAI',
    geckoName: 'dai',
    unicode: '$',
    decimals: 3
  },
  USDT: {
    currencyName: 'Tether USD',
    geckoName: 'tether',
    unicode: '$',
    decimals: 3
  },
  LTC: {
    currencyName: 'Litecoin',
    geckoName: 'litecoin'
  },
  CHA: {
    currencyName: 'Chaucha',
    geckoName: undefined
  },
  LUK: {
    currencyName: 'Luka',
    geckoName: undefined
  },
  BCH: {
    currencyName: 'Bitcoin Cash',
    geckoName: 'bitcoin-cash'
  },
  DASH: {
    currencyName: 'Dash',
    geckoName: 'dash'
  },
  EOS: {
    currencyName: 'EOS',
    geckoName: 'eos'
  },
  DOT: {
    currencyName: 'DOT',
    geckoName: 'dot'
  }
}

export { currenciesCodeArray, currenciesNames, getCoinData }
