import React, { useEffect, useState } from 'react'
import PropTypes from 'prop-types'
import { Div, Text } from 'atomize-jnh'
import { FlexibleXYPlot, XAxis, YAxis, GradientDefs, LineSeries, Crosshair } from 'react-vis'
import numberWithCommas from '../../../helpers/numberWithCommas'
import { useIntl } from 'gatsby-plugin-intl'
import { getCoinData } from '../../../data/currencies'
import './styles.sass'
import './styles.css'

function kFormatter (number) {
  if (number.toString().length >= 6) {
    const numberString = number.toString()
    const ks = numberString.slice(0, numberString.length - 3)
    const kString = `$${ks} k`

    return kString
  }
  return number
}

function Graph ({ coinId, pairCode }) {
  const [graphData, setGraphData] = useState(null)
  const [crosshairValues, setCrosshairValues] = useState(null)

  const intl = useIntl()
  const data = getCoinData(intl)
  const graphLimit = '24'

  async function fetchData () {
    const data = await fetch('https://client.orionx.com/graphql', {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
        fingerprint: 'cualquierfingerprint'
      },
      body: JSON.stringify([
        {
          query: `query {marketStats(marketCode: "${pairCode}", aggregation: d1, limit: ${graphLimit}){\n open\n close\n fromDate\n toDate\n }}`
        }
      ])
    })

    const resultData = await data.json()

    const auxGraphData = []
    let monthData
    if (coinId !== 'default') {
      const statsArray = resultData[0].data.marketStats

      for (const index in statsArray) {
        const date = new Date(statsArray[index].toDate)
        const ISOdate = date.toISOString()
        const x = `${ISOdate[8]}${ISOdate[9]}-${ISOdate[5]}${ISOdate[6]}`

        // Esta funcion y validacion es necesaria, ya que cuando y=null, el grafico marca 0 y se ve muy mal
        const y = () => {
          const tickYData = statsArray[index].close
          if (tickYData === null) {
            const previousTickData = statsArray[index - 1].close
            return previousTickData
          }
          return tickYData
        }

        monthData = {
          x: x,
          y: y()
        }
        auxGraphData.push(monthData)
      }

      setGraphData(auxGraphData)
    }
    return resultData
  }

  useEffect(() => {
    fetchData()
  }, [pairCode, coinId])

  function xAxisFormatter (t, i) {
    if (i % 4 === 0) {
      return (
        <tspan>
          <tspan dy="1em">{t}</tspan>
        </tspan>
      )
    }
  }

  function yAxisFormatter (t, i) {
    return (
      <tspan>
        <tspan x="0">{kFormatter(t)}</tspan>
      </tspan>
    )
  }

  function onNearestX (value, { index }) {
    setCrosshairValues(graphData[index].y !== null && graphData[index])
  }

  function renderGraphData () {
    if (graphData) {
      return (
        <>
          <Text m={{ l: '36px', b: '0' }} textSize="body" textWeight="600">
            {`${data[coinId].name}`} Price
          </Text>
          <Div h="0" d="flex">
            <Text m={{ l: '36px', b: '0' }} textSize="display1">
              {numberWithCommas(graphData[graphData.length - 1].y)}
            </Text>
            <Text m={{ t: '5px', b: '0' }} textSize="title">
              .00 CLP
            </Text>
          </Div>

          <FlexibleXYPlot
            onMouseLeave={() => setCrosshairValues(null)}
            yPadding={30}
            margin={{ left: 80, top: 36 }}
            xType="ordinal">
            <GradientDefs>
              <linearGradient id="CoolGradient" x1="0" x2="0" y1="0" y2="1">
                <stop offset="0%" stopColor="#37b7ff" stopOpacity={0.4} />
                <stop offset="100%" stopColor="#fff" stopOpacity={1} />
              </linearGradient>
            </GradientDefs>

            <XAxis
              tickSize="4"
              tickSizeOuter="0"
              tickSizeInner="4"
              tickFormat={(t, i) => xAxisFormatter(t, i)}
              style={{
                ticks: { stroke: '#c3c3c3' },
                text: { stroke: 'none', fill: '#c3c3c3', fontSize: '14px' }
              }}
            />

            <YAxis
              tickFormat={(t, i) => yAxisFormatter(t, i)}
              tickTotal={5}
              tickSize="4"
              tickSizeOuter="0"
              tickSizeInner="4"
              style={{
                ticks: { stroke: '#c3c3c3' },
                text: {
                  stroke: 'none',
                  fill: '#c3c3c3',
                  fontSize: '12px'
                }
              }}
            />
            <LineSeries
              getNull={d => d.y !== null}
              onNearestX={onNearestX}
              strokeWidth="3px"
              stroke="#77d4fe"
              data={graphData}
            />
            {crosshairValues && (
              <Crosshair
                style={{ line: { border: '1px dashed #77d4fe', background: '#fff' } }}
                values={[crosshairValues]}>
                <Div
                  w={{ xs: '120px', md: '130px' }}
                  textAlign="center"
                  style={{
                    border: '1px solid rgb(176, 178, 180)',
                    borderRadius: '5px'
                  }}>
                  <Text textColor="#72889f" textSize="body">
                    ${numberWithCommas(crosshairValues.y)} CLP
                  </Text>
                </Div>
              </Crosshair>
            )}
          </FlexibleXYPlot>
        </>
      )
    }
    return (
      <Div pos="absolute" left="40%" top="50%">
        <div className="sk-chase">
          <div className="sk-chase-dot"></div>
          <div className="sk-chase-dot"></div>
          <div className="sk-chase-dot"></div>
          <div className="sk-chase-dot"></div>
          <div className="sk-chase-dot"></div>
          <div className="sk-chase-dot"></div>
        </div>
      </Div>
    )
  }

  return (
    <Div
      m={{ t: '1rem' }}
      p={{ t: '10px' }}
      shadow="4"
      bg="#fff"
      rounded="md"
      h={{ xs: '23rem', md: '23rem' }}>
      <Div h={{ xs: '18rem', md: '18.5rem' }} w={{ xs: '107%', md: '102%' }} m={{ l: '-2%' }}>
        {renderGraphData()}
      </Div>
    </Div>
  )
}

Graph.propTypes = {
  coinId: PropTypes.string,
  pairCode: PropTypes.string
}

export default Graph
