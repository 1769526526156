import React, { useEffect, useState } from 'react'
import PropTypes from 'prop-types'
import { Div, Col, Row, Text, Button } from 'atomize-jnh'
import { useIntl } from 'gatsby-plugin-intl'
import numberWithCommas from '../../../helpers/numberWithCommas'
import percentFormatter from '../../../helpers/percentFormatter'
import ReactTooltip from 'react-tooltip'

const defaultButtonStyles = {
  h: { xs: '2.7rem', md: '37px' },
  p: { x: '1rem' },
  textSize: { xs: 'subheader', md: 'body' },
  w: { xs: '200px', md: '200px' },
  m: { r: { md: '10.5px' } },
  rounded: 'circle',
  hoverShadow: '3',
  textWeight: '900'
}

const primaryButtonStyles = {
  textColor: 'white',
  hoverTextColor: 'white',
  bg: '#4d68f0',
  hoverBg: '#1a3ded',
  borderColor: '#4d68f0',
  hoverBorderColor: 'info900',
  textWeight: { xs: '600' },
  border: '1px solid',
  m: { xs: '0 auto', md: '0 0' }
}

function OperateCard ({ pairCode, coinId }) {
  const [pairData, setData] = useState(null)
  const intl = useIntl()
  useEffect(() => {
    fetch('https://stats.orionx.com/ticker')
      .then(response => response.json())
      .then(resultData => {
        setData(resultData[`${pairCode}`])
      })
  }, [coinId])
  return (
    <Div
      m={{ t: '1rem' }}
      p={{ t: '48px', l: { xs: '24px', md: '24px' }, r: '0px', b: '24px' }}
      shadow="4"
      bg="#fff"
      rounded="md"
      pos="relative"
      h="23rem">
      <ReactTooltip />
      <Div w="80%" m="0 auto">
        <Row>
          <Col size="6">
            <Div m={{ b: '2rem' }}>
              <Text
                data-tip={intl.formatMessage({ id: 'marketSimple.operate.openDescription' })}
                textSize="body"
                textTransform="uppercase"
                textColor="#b0b2b4">
                {intl.formatMessage({ id: 'marketSimple.operate.open' })}
              </Text>
              <Text>
                {pairData != null
                  ? `$${numberWithCommas(
                      (pairData.lastPrice - pairData.lastPrice * pairData.variation24h).toFixed(0)
                    )}`
                  : '-'}
              </Text>
            </Div>
          </Col>
          <Col size="6">
            <Div>
              <Text
                data-tip={intl.formatMessage({ id: 'marketSimple.operate.changeDescription' })}
                textSize="body"
                textTransform="uppercase"
                textColor="#b0b2b4">
                {intl.formatMessage({ id: 'marketSimple.operate.change' })}
              </Text>
              <Text>
                {pairData != null ? `${percentFormatter(pairData.variation24h * 100)}% ` : '-'}
              </Text>
            </Div>
          </Col>
          <Col size="6">
            <Div m={{ b: '2rem' }}>
              <Text
                data-tip={intl.formatMessage({ id: 'marketSimple.operate.volumeDescription' })}
                textSize="body"
                textTransform="uppercase"
                textColor="#b0b2b4">
                {intl.formatMessage({ id: 'marketSimple.operate.volume' })}{' '}
              </Text>
              <Text>{pairData != null ? `${pairData.volume24h.toFixed(4)} ${coinId}` : '-'}</Text>
            </Div>
          </Col>
          <Col size="6">
            <Div>
              <Text
                data-tip={intl.formatMessage({ id: 'marketSimple.operate.spreadDescription' })}
                textSize="body"
                textTransform="uppercase"
                textColor="#b0b2b4">
                {intl.formatMessage({ id: 'marketSimple.operate.spread' })}
              </Text>
              <Text>{pairData != null ? `$${numberWithCommas(pairData.spread)}` : '-'}</Text>
            </Div>
          </Col>
          <Col size="6">
            <Div m={{ b: '3rem' }}>
              <Text
                data-tip={intl.formatMessage({ id: 'marketSimple.operate.sellPriceDescription' })}
                textSize="body"
                textTransform="uppercase"
                textColor="#b0b2b4">
                {intl.formatMessage({ id: 'marketSimple.operate.sellPrice' })}
              </Text>
              <Text>{pairData != null ? `$${numberWithCommas(pairData.bid)}` : '-'}</Text>
            </Div>
          </Col>
          <Col size="6">
            <Div>
              <Text
                data-tip={intl.formatMessage({ id: 'marketSimple.operate.buyPriceDescription' })}
                textSize="body"
                textTransform="uppercase"
                textColor="#b0b2b4">
                {intl.formatMessage({ id: 'marketSimple.operate.buyPrice' })}
              </Text>
              <Text>{pairData != null ? `$${numberWithCommas(pairData.ask)}` : '-'}</Text>
            </Div>
          </Col>
        </Row>
      </Div>
      <Div pos="absolute" bottom="24px" left={{ xs: '5px', md: '48px' }} right="0" m="auto">
        <Row>
          <Col size={{ xs: '12', md: '10' }} d="flex" justify="center">
            <Button
              onClick={() => (window.location = 'https://app.orionx.com/instant/sell')}
              {...defaultButtonStyles}
              {...primaryButtonStyles}>
              {intl.formatMessage({ id: 'marketSimple.operate.button' })}
            </Button>
          </Col>
        </Row>
      </Div>
    </Div>
  )
}

OperateCard.propTypes = {
  pairCode: PropTypes.string,
  coinId: PropTypes.string
}

export default OperateCard
