import React from 'react'
import { Div, Text, Icon } from 'atomize-jnh'
import { navigate } from 'gatsby'
import { useIntl } from 'gatsby-plugin-intl'

function DirectoryInfo (props) {
  const intl = useIntl()

  return (
    <Div d="flex">
      <Icon
        onClick={() => navigate('/')}
        cursor="pointer"
        name="Home"
        color="#787878"
        size="20px"
      />
      <Text style={{ userSelect: 'none' }} m={{ l: '5px' }} textColor="#787878">
        {'>'}
      </Text>
      <Text
        style={{ userSelect: 'none' }}
        onClick={() => navigate('/criptomonedas-chile')}
        cursor="pointer"
        m={{ l: '5px' }}
        textColor="#787878">
        {intl.formatMessage({ id: 'marketSimple.directory.0' })}
      </Text>
      <Text m={{ l: '5px' }} textColor="#787878">
        {'>'}
      </Text>
      <Text style={{ userSelect: 'none' }} m={{ l: '5px' }} textColor="#787878">
        {intl.formatMessage({ id: 'marketSimple.directory.1' })}
      </Text>
    </Div>
  )
}

export default DirectoryInfo
