import React from 'react'
import PropTypes from 'prop-types'
import { Div, Text } from 'atomize-jnh'
import CoinCircle from '../../CoinCircle'
import { getCoinData } from '../../../data/currencies'
import { useIntl } from 'gatsby-plugin-intl'
function CoinTitle ({ coinId }) {
  const intl = useIntl()

  const data = getCoinData(intl)

  return coinId === 'default' ? (
    <div />
  ) : (
    <Div d="flex">
      <CoinCircle size={35} currencyCode={coinId} />
      <Text m={{ t: '2px', l: '10px' }} textSize="title" textWeight="700">
        {data[coinId].name}
      </Text>
    </Div>
  )
}

export default CoinTitle

CoinTitle.propTypes = {
  coinId: PropTypes.string
}
